:root {
  --wheel-font: 'Aldrich', 'Quicksand', sans-serif;
  --wheel-size: 500px;
  --wheel-slice-spacing: 50px;
  --wheel-border-size: 5px;
  --wheel-color: #8b0404;
  --neutral-color: white;
  --PI: 3.14159265358979;
  --nb-item: 0;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 30;
  --spinning-duration: 7s;
  --reset-duration: 0.1s;
}

/* Responsive size adjustments */
@media (max-width: 768px) {
  :root {
    --wheel-size: 500px; /* Smaller wheel size for mobile devices */
  }
  
}

@media (max-width: 480px) {
  :root {
    --wheel-size: 350px; /* Even smaller for very small screens */
  }
}

@media (max-width: 320px) {
  :root {
    --wheel-size: 275px; /* Even smaller for very small screens */
  }
}

.wheel-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  padding: 3px;
  margin: auto;
  background-color: var(--neutral-color);
  border: solid var(--wheel-color) 3px;
  border-radius: 50%;
  user-select: none;
}

.wheel-container::before,
.wheel-container::after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: solid transparent 20px;
  border-left-width: 0;
}

.wheel-container::before {
  right: 0px;
  border-right-color: var(--wheel-color);
}

.wheel-container::after {
  right: -5px;
  border-right-color: var(--neutral-color);
}

.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  border: solid var(--wheel-color) var(--wheel-border-size);
  background-color: var(--wheel-color);
  transition: transform var(--spinning-duration) var(--spin-timing-function);
  transform: rotate(0deg);
  cursor: pointer;
}

/* @keyframes spin {
  0% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.4, 0.0, 1, 1);
  }
  70% {
    transform: rotate(calc(var(--nb-turns) * 360deg));
    animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  100% {
    transform: rotate(calc(var(--nb-turns) * 360deg + (-360deg * var(--selected-item) / var(--nb-item))));
  }
} */
@keyframes spin {
  0% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  }
  100% {
    transform: rotate(calc(var(--nb-turns) * 360deg + (-360deg * var(--selected-item) / var(--nb-item))));
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
  }
}
.wheel.spinning {
  animation: spin var(--spinning-duration) ease-out;
  animation-fill-mode: forwards;
}

.wheel::after {
  background-image: url('../images/icon.png');
  background-size: 100% auto;
  display: block;
  position: absolute;
  content: '';
  background-color: rgba(136, 20, 20, 0.788);/* var(--neutral-color); */
  width: 50px;
  height: 50px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid rgba(136, 20, 20, 0.575);
}

.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));
  color: var(--neutral-color);
  text-align: right;
  padding: 0 25px 0 50px;
  font-family: var(--wheel-font);
  font-size: calc(12px + 0.5vw); /* Adjust font size for better readability */
  line-height: 1.2;
}

.wheel-item:before {
  content: ' ';
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 80%;/* left: 50%; */
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.4;
  --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
  --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
  border: solid transparent calc(var(--slice-width) / 1.3); /* Increase the background size */
  border-left: solid transparent 0;
  border-right: solid var(--neutral-color) calc(var(--wheel-size) / 1.3);
}
/* Base styles for .wheelItem */
.wheelItem {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  display: block;
  margin: 0 20px;
}

/* Basic spin specific styling */
.basic-spin .wheelItem {
  font-size: calc(0.25em + 0.5vw); /* Specific font size for basic spin */
}

/* Premium spin */
.premium-spin .wheelItem {
  font-size: calc(0.25em + 0.5vw); /* Different size for premium spin */
}

/* Jackpot spin */
.jackpot-spin .wheelItem {
  font-size: calc(0.25em + 0.5vw); /* Different size for jackpot spin */
}

.special-item:before {
  border-right-color: #FFD700; /* Example color for the special item */
  opacity: 0.7; /* You can adjust opacity or other styles */
}

/* Optional: You can also change the text color or other styles */
.special-item .wheelItem {
  color: #030303;
}
.special-item-premium:before {
  border-right-color: #ADD8E6; /* Light Blue for Premium Spin */
  opacity: 0.7; /* Adjust the opacity */
}

.special-item-jackpot:before {
  border-right-color: #90EE90; /* Light Green for Jackpot Spin */
  opacity: 0.7; /* Adjust the opacity */
}

/* Optional: Change text color or other styles */
.special-item-premium .wheelItem {
  color: #00008B; /* Dark Blue for text in Premium Spin */
}

.special-item-jackpot .wheelItem {
  color: #006400; /* Dark Green for text in Jackpot Spin */
}
