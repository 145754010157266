/* BackgroundMusic.css */
.volume-control-icon {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.2s ease-in-out;
  }
  
  .volume-control-icon:hover {
    transform: scale(1.2);
  }
  
  .volume-popup {
    position: fixed;
    top: 60px;
    right: 20px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    z-index: 1000;
    text-align: center;
  }
  
  .mute-btn {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s;
  }
  
  .mute-btn:hover {
    background-color: #0056b3;
  }
  
  input[type="range"] {
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
  }
  