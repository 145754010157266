/* HistoryTable.module.css */

.historyContainer {
    position: relative;
  }
  
  .historyTable {
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  .pagination{
    margin: 5px 0;
    font-size: 12px;
    float: right;
    font-weight: 600;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .historyTable table {
   
    width: 100%;
    border-collapse: collapse;
  }
  
  .historyTable th,
  .historyTable td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .historyTable th {
    background-color: #333;
    color: white;
  }
  
  .historyTable td {
    background-color: white;
    color: #333;
    font-size: 14px;
  }
  
  .historyToggleButton {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  
  .historyModalOverlay {
    display: none;
  }
  
  .historyModal {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    overflow-y: auto;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modalContent {
    overflow-y: auto;
    .pagination{
      margin: 5px 0;
      font-size: 12px;
      float: right;
    }
  
  }
  
  @media (max-width: 768px) {
    .historyTable {
      display: none;
    }
   
   
    .textShadow {
      text-shadow: 0.08em 0.08em 0.08em rgba(0, 0, 0, 0.4);
  }
  
  .textShadowInverted {
      text-shadow: 0.08em 0.08em 0.08em rgba(255, 255, 255, 0.4);
      color: #000;
  }
     table {
      width: 100%;
      background-color: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-top: 20px;
      border: 1px solid gray ;
      border-color: gray;
    }
    
     th,
     td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
    
     th {
      background-color: #333;
      color: white;
    }
    
     td {
      background-color: white;
      color: #333;
      font-size: 12px;
      padding: 5px;
    }
    
    .historyModalOverlay {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      z-index: 1000;
      
    }
  
    .historyToggleButton {
      display: flex;
    }
  }
  .hoverable {
    float: left;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
  }
  
  .hoverable:hover {
    color: rgb(19, 10, 151); /* Change the color to whatever you prefer */
 
 
  }
  